'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {INameId, PackageConfig} from "../../core/interfaces";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Button} from "@mui/material";
import {Pagination} from "../../core/components/Pagination";
import {BookFilterSearchPaginationParams} from "../../core/services/utils";
import {deleteReadingTemplatePlan} from "../../services/templatePlans";
import {Form} from "react-bootstrap";
import {map} from "lodash-es";
import {getAllInternalAgeGroups} from "../../services/internalAgeGroups";
import {createPackageConfig, getAllPackageConfigs, updatePackageConfig} from "../../services/packageConfigs";
import {PackageConfigModal} from "./PacakgeConfigModal";

export const PackagesListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,
    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [packageCofigs, setPackageCofigs] = useState<PackageConfig[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentBook, setcurrentBook] = useState<PackageConfig>()
    const [showCreateBookModal, setShowCreateBookModal] = useState<boolean>(false)
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [allAgeGroups, setAllAgeGroups] = useState<INameId[]>([])
    const [filterInternalAgeGroup, setFilterInternalAgeGroup] = useState<string>('')

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            var getAllParams: BookFilterSearchPaginationParams = {
                page: 0,
                size: 1000
            };

            (async () => {
                const [allAgeGroups] = await Promise.all(
                    [getAllInternalAgeGroups(getAllParams)])
                setAllAgeGroups(allAgeGroups.data.content)
            })()

            const params: BookFilterSearchPaginationParams = {
                page,
                size,
                sortBy,
            };
            params.ageGroup = filterInternalAgeGroup
            const response = await getAllPackageConfigs(params)
            setTotalElements(response.data.totalElements)
            setPackageCofigs(response.data.content)
            setPage(page + 1)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy, filterInternalAgeGroup])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy, filterInternalAgeGroup])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const deleteCall = (id:string) => {
        deleteReadingTemplatePlan(id).then(() => getData());
        return undefined;
    }

    const handleSearchParams = useCallback(
        async (col: IColumn, q: string) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            searchParams.current = {
                ...searchParams.current,
                [col.accessor]: q,
            }

            const newTimer = setTimeout(async () => {
                setLoading(true)
                try {
                    const params: BookFilterSearchPaginationParams = {
                        page: 0,
                        size,
                        sortBy,
                    }

                    params.text = searchParams.current.name
                    params.ageGroup = filterInternalAgeGroup
                    const { data: res, status } = await getAllPackageConfigs(params)
                    if (status === 200) {
                        setPackageCofigs(res.content)
                        setTotalElements(res.totalElements)
                        setSize(res.size)
                        setPage(res.pageNumber + 1)
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                    setLoading(false)
                }
            }, 2000)
            timer.current = newTimer
        },
        [page, size, sortBy],
    )

    const columns: IColumn[] = [
        {
            title: 'Name',
            accessor: 'name',
            sortable: true,
            searchable: true
        },
        {
            title: 'Number of Books',
            accessor: 'numberOfBooks',
            sortable: false,
            searchable: false
        },
        {
            title: 'Internal Age Group',
            accessor: '',
            render: (row) => (
                <div className="d-flex flex-column">
                    <span>{row.ageGroup.name}</span>
                </div>
            ),
        },
        {
            title: 'Active',
            accessor: 'active',
            render: (row) => (
                <div className="d-flex flex-column">
                    <span>{row.active ? 'Yes' : 'No'}</span>
                </div>
            ),
        },
        {
            title: 'Price',
            accessor: 'price',
            sortable: false,
            searchable: false
        },
        {
            title: 'Discount Price',
            accessor: 'discountPrice',
            sortable: false,
            searchable: false
        },
        {
            title: 'Edit',
            accessor: '',
            render: (row) => (
                <div
                    style={{ cursor: 'pointer' }}
                    className="view"
                    onClick={() => {
                        setEditMode(true)
                        setcurrentBook(row)
                        setShowCreateBookModal(true)
                    }}
                >
                    Edit
                </div>
            ),
        },
        {
            title: 'Delete',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onDoubleClick={(e) => deleteCall(row.id)}>
                    Delete
                </Button>
            ),
        }
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader/>}
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                }}>
                    Filters
                    <div className="d-flex gap-2">
                        <Form.Label
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Helvetica Neue',
                                fontWeight: '700',
                                lineHeight: '150%',
                                fontStyle: 'normal',
                                width: '132px',
                            }}
                            className="d-flex flex-column mb-0"
                        >
                            Internal Age Group
                        </Form.Label>
                        <Form.Select className="d-flex flex-column mb-0"

                                     onChange={(e) => {
                                         const val = e.target.value
                                         setFilterInternalAgeGroup(val);
                                     }}
                        >
                            <option disabled value={''}>
                                Select Internal Age Group
                            </option>
                            <option  value={''}>
                                ALL
                            </option>
                            {map(allAgeGroups, (pub) => (
                                <option id={pub.id} key={pub.id} value={pub.id}>
                                    {pub.name}
                                </option>
                            ))}
                        </Form.Select>
                    </div>
                </div>

                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                >
                    <h2>Packages</h2>
                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small" onClick={() => {
                            setEditMode(false)
                            setShowCreateBookModal(true)
                            setcurrentBook(undefined)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex"/>
                                <span>Add new Package</span>
                            </div>
                        </Button>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <Table
                        handleSearch={handleSearchParams}
                        isCheck={isCheck}
                        columns={columns}
                        handleSortBy={handleSortBy}
                        data={packageCofigs}
                    />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                <PackageConfigModal
                    setEditMode={setEditMode}
                    setShowCreateModal={setShowCreateBookModal}
                    setAllList={setPackageCofigs}
                    showCreateModal={showCreateBookModal}
                    editMode={editMode}
                    page={page + 1}
                    selectedPackage={currentBook}
                    size={size}
                    setTotalElements={setTotalElements}
                    createCall={createPackageConfig}
                    updateCall={updatePackageConfig}
                    getAllCall={getAllPackageConfigs}
                />
            </div>
        </>
    )
}