import {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {MessageBox} from '../../core/components/MessageBox'
import {UserKidInGroup} from "../../core/interfaces";
import {addKidInGroup, getAllKids} from "../../services/readingPlans";

export interface AddKidModalProps {
  show: boolean
  setShow: (show: boolean) => void
  groupId?: string
}

export const AddKidModal = (
  props: AddKidModalProps,
): JSX.Element => {
  const {groupId,
      show,
    setShow,
  } = props

  const [isError, setIsError] = useState<boolean>(false)
  const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [kids, setKids] = useState<UserKidInGroup[]>([])
  const [selectedKid, setSelectedKid] = useState<string>('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllKids();
        setKids(response.data)
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    }
    fetchData()
  }, [])

  const handleClose = () => {
    setShow(false)
  }

  const handleSave = async () => {
    console.log(selectedKid)
    console.log(groupId)
    if (selectedKid && groupId) {
      try {
        await addKidInGroup(groupId, selectedKid)
        setShow(false)
        window.location.reload()
      } catch (e) {
        if (e instanceof Error) {
          setIsError(true)
          setShowMessageBox(true)
          setMessage(e.message)
        } else {
          console.error(e)
        }
      }
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add kid
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formKidSelect">
              <Form.Label>Select Kid</Form.Label>
              <Form.Control as="select" onChange={(e) => setSelectedKid(e.target.value)}>
                <option value="">Select a kid</option>
                {kids.map((kid) => (
                    <option key={kid.kid.id} value={kid.kid.id}>
                      {kid.firstName} {kid.lastName} - {kid.kid.name} - {kid.email} - {kid.kid.groupDetails.name} - {kid.kid.groupDetails.institutionName}
                    </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>

          <Button
            onClick={handleClose}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <MessageBox
        message={message}
        setShowMessageBox={setShowMessageBox}
        showMessageBox={showMessageBox}
        isError={isError}
      />
    </>
  )
}
