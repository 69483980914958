'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IInstitutionGroupDetails, IReadingPlanSummary, UserKidInGroup} from "../../core/interfaces";
import {getInstitutionGroup} from "../../services/institutionGroups";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Pagination} from "../../core/components/Pagination";
import {Button} from "@mui/material";
import {SimpleSearchPaginationParams} from "../../core/services/utils";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft, CheckCircle} from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    activateReadingPlan,
    assignUsersPlan,
    createReadingPlan,
    deleteReadingPlan,
    generateSchedulePlan, getAllKidsForIGroup,
    getAllPlansForIGroup, removeKidFromGroup,
    updateReadingPlan
} from "../../services/readingPlans";
import {concat} from "lodash-es";
import {AddKidModal} from "./AddKidModal";

export interface DateFromTimestampProps {
    date: number
}
export const DateFromTimestamp = ( props: DateFromTimestampProps): JSX.Element => {
    const {
        date
    } = props
    return (
        <span>
            {(new Date(date)).toDateString()}
        </span>
    );
}

export interface ActiveMarkerProps {
    active: boolean
}
export const ActiveMarker = ( props: ActiveMarkerProps): JSX.Element => {
    const {
        active
    } = props
    return (
        <>
            {
                active === true ?
                    <CheckCircleIcon/>
                :
                    <CancelIcon/>
            }
        </>
    );
}
export const UsersInGroupListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,

    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )
    const {iGroupId} = useParams()
    const [iGroupDetails, setIGroupDetails] = useState<IInstitutionGroupDetails>()

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [users, setUsers] = useState<UserKidInGroup[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentPlan, setCurrentPlan] = useState<IReadingPlanSummary>()
    const [showAddKidModal, setShowAddKidModal] = useState<boolean>(false)
    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const params: SimpleSearchPaginationParams = {
                page,
                size,
                sortBy,
            };
            params.text = searchParams.current.name
            const response = await getAllKidsForIGroup(iGroupId, params)
            setTotalElements(response.data.totalElements)
            setUsers(response.data.content)
            setPage(page + 1)
            const groupResponse = await getInstitutionGroup(iGroupId)
            setIGroupDetails(groupResponse.data)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy])

    useEffect(() => {
        getData()
    }, [getData, page, size, sortBy])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const handleSearchParams = useCallback(
        async (col: IColumn, q: string) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            searchParams.current = {
                ...searchParams.current,
                [col.accessor]: q,
            }

            const newTimer = setTimeout(async () => {
                setLoading(true)
                try {
                    const params: SimpleSearchPaginationParams = {
                        page: 0,
                        size,
                        sortBy,
                    }

                    params.text = searchParams.current.name
                    const { data: res, status } = await getAllKidsForIGroup(iGroupId, params)
                    if (status === 200) {
                        setUsers(res.content)
                        setTotalElements(res.totalElements)
                        setSize(res.size)
                        setPage(res.pageNumber + 1)
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                    setLoading(false)
                }
            }, 2000)
            timer.current = newTimer
        },
        [page, size, sortBy],
    )

    function assignUsers(id :string) {
        assignUsersPlan(id).then(value => window.location.reload());
    }

    function generateSchedule(id :string) {
        generateSchedulePlan(id).then(value => window.location.reload());
    }

    function activate(id) {
        activateReadingPlan(id).then(response => {
            if (!response.data.success) {
                alert(response.data.message);
            } else {
                window.location.reload();
            }
        })
    }

    function removeCall(id: string | undefined) {
        if (id) {
            removeKidFromGroup(iGroupId, id).then(value => window.location.reload());
        }
    }

    const columns: IColumn[] = [
        {
            title: 'id',
            accessor: 'id',
        },
        {
            title: 'First Name',
            accessor: 'firstName',
            sortable: true,
            searchable: true
        },
        {
            title: 'Last Name',
            accessor: 'lastName',
            sortable: true,
            searchable: true
        },
        {
            title: 'Phone',
            accessor: 'phone',
            sortable: true,
            searchable: true
        },
        {
            title: 'Email',
            accessor: 'email',
            sortable: true,
            searchable: true

        },
        {
            title: 'Kid Name',
            accessor: 'kid.name',
            sortable: true,
            render: (row) => row.kid?.name || ''
        },
        {
            title: 'Kid Birthdate',
            accessor: 'kid.birthdate',
            render: (row) => row.kid?.birthdate || ''
        },
        {
            title: 'Kid Id',
            accessor: 'kid.id',
            render: (row) => row.kid?.id || ''

        },
        {
            title: 'Remove',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onDoubleClick={(e) => removeCall(row.kid?.id)}>
                    Delete
                </Button>
            ),
        },
    ]

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    {iGroupDetails ?
                            <>
                                <Link to={`/institutions/${iGroupDetails.institutionId}/groups`}>
                                    <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                                </Link>
                                <h2>Users and kids in {concat(iGroupDetails.institutionName, " - ", iGroupDetails.name)}</h2>
                            </>
                            :
                            <></>
                    }
                    <div className="d-flex gap-2">
                        <Button variant="contained" size="small" onClick={() => {
                            setShowAddKidModal(true)
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Add kid in group</span>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                <Table
                    handleSearch={handleSearchParams}
                    isCheck={isCheck}
                    columns={columns}
                    handleSortBy={handleSortBy}
                    data={users}
                />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />
                <AddKidModal show={showAddKidModal} setShow={setShowAddKidModal} groupId={iGroupId} />
            </div>
        </>
    )
}
