import {yupResolver} from '@hookform/resolvers/yup'
import {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'
import {MessageBox} from '../../core/components/MessageBox'
import {IBook, ICreatePackageConfig, INameId, PackageConfig, UploadedPhoto} from "../../core/interfaces";
import {BookFilterSearchPaginationParams, Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import {AxiosResponse} from 'axios';
import {map} from "lodash-es";
import {getAllInternalAgeGroups} from "../../services/internalAgeGroups";
import {getAllCategories} from "../../services/categories";
import {getAllBooks} from "../../services/books";
import {UUID} from "node:crypto";
import {BookPhotosUploader} from "../component/BookPhotosUploader";


export interface PlanTemplateModalProps {
    showCreateModal: boolean
    setShowCreateModal: (show: boolean) => void
    setEditMode: (editMode: boolean) => void
    editMode: boolean
    page: number
    size: number
    selectedPackage: PackageConfig
    setTotalElements: (elements: number) => void
    setAllList: (categories: PackageConfig[]) => void
    createCall: (data: ICreatePackageConfig) => Promise<AxiosResponse<PackageConfig>>
    updateCall: (id: string, data: ICreatePackageConfig) => Promise<AxiosResponse<PackageConfig>>
    getAllCall: (params?: SimpleSearchPaginationParams) => Promise<AxiosResponse<Pagination<PackageConfig>>>
}

export interface CreatePackageConfigModeled {
    name?:string
    description?:string
    type?:string
    numberOfBooks?: number
    price?: number
    discountPrice?: number
    active?: boolean
    ageGroupId?: string
    photos?: string[]
    weight?: number
    slug?: string
}


export const PackageConfigModal = (
    props: PlanTemplateModalProps,
): JSX.Element => {
    const {
        setShowCreateModal,
        setAllList,
        showCreateModal,
        setEditMode,
        editMode,
        page,
        size,
        setTotalElements,
        createCall,
        updateCall,
        getAllCall

    } = props


    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [innerType, setInnerType] = useState<string>('')
    const [innerAgeGroup, setInnerAgeGroup] = useState<string>('')
    const [allAgeGroups, setAllAgeGroups] = useState<INameId[]>([])
    const [allCategories, setAllCategories] = useState<INameId[]>([])
    const [allBooks, setAllBooks] = useState<IBook[]>([])

    const [isDynamic, setIsDynamic] = useState<boolean>(true)
    const [isStatic, setIsStatic] = useState<boolean>(false)
    const [selectedEntries, setSelectedEntries] = useState<string[]>([]);

    const [isUploading, setIsUploading] = useState<boolean>(false)

    const [uploadedImages, setUploadedImages] = useState<UploadedPhoto[]>([getEmptyImage()])
    function getEmptyImage() {
        return {name: "", url: "", key: "", main: false, index: 0};
    }

    useEffect(() => {
        var getAllParams: SimpleSearchPaginationParams = {
            page: 0,
            size: 10000
        };
        (async () => {
            const [allAgeGroups] = await Promise.all(
                [getAllInternalAgeGroups(getAllParams)])
            setAllAgeGroups(allAgeGroups.data.content)
        })()

        if (editMode) {
            setValue('name', props?.selectedPackage?.name)
            setValue('description', props?.selectedPackage?.description)
            setValue('numberOfBooks', props?.selectedPackage?.numberOfBooks)
            setValue('price', props?.selectedPackage?.price)
            setValue('discountPrice', props?.selectedPackage?.discountPrice)
            setValue('active', props?.selectedPackage?.active)
            setValue('type', props?.selectedPackage?.type)
            setValue('photos', props?.selectedPackage?.photos.map(value => value.url))
            setValue('ageGroupId', props?.selectedPackage?.ageGroup.id)
            setValue('weight', props?.selectedPackage?.weight)
            setValue('slug', props?.selectedPackage?.slug)
            setSelectedEntries(props?.selectedPackage?.selectedEntries ? Object.entries(props?.selectedPackage?.selectedEntries).flatMap(([key, value]) => Array(value).fill(key)) : [])
            setInnerType(props?.selectedPackage?.type)
            const photos = props?.selectedPackage?.photos.map(value => {
                return {key: value.key, url: value.url, name: value.key, main: value.main, index: value.index}
            });
            if (photos.filter(value => value.key==='').length === 0) {
                photos.push(getEmptyImage());
            }
            photos.sort((a, b) => (b.main ? 1 : a.main ? -1 : a.key === '' ? 1 : b.key === '' ? -1 : 0));
            setUploadedImages(photos)

        } else {
            setSelectedEntries([])
            setInnerType('')
            const photos = [];
            photos.push(getEmptyImage());
            setUploadedImages(photos)
        }
    }, [editMode])

    useEffect(() => {
        var getAllParams: BookFilterSearchPaginationParams = {
            page: 0,
            size: 10000
        };

        if (innerType === 'DYNAMIC') {
            setIsDynamic(true);
            setIsStatic(false);
            (async () => {
                const [allAgeGroups] = await Promise.all(
                    [getAllCategories(getAllParams)])
                setAllCategories(allAgeGroups.data.content)
            })()
        } else {
            setIsDynamic(false);
            setIsStatic(true);

            (async () => {
                getAllParams.ageGroup = innerAgeGroup;
                const [allBooks] = await Promise.all(
                    [getAllBooks(getAllParams)])
                setAllBooks(allBooks.data.content)
            })()
        }
    }, [editMode, innerType, innerAgeGroup])

    const schema = yup.object().shape({
        name:yup.string().optional(),
        description:yup.string().optional(),
        numberOfBooks:yup.number().min(1).required(),
        price:yup.number().min(1).required(),
        discountPrice:yup.number().min(0),
        active: yup.boolean(),
        type: yup.string().required(),
        ageGroupId: yup.string(),
        photos: yup.array(),
        weight: yup.number().min(0).required(),
        slug: yup.string()
    })

    const {
        handleSubmit,
        register,
        formState: {errors, dirtyFields},
        reset,
        setValue,
        getValues
    } = useForm<CreatePackageConfigModeled>({
        mode: 'onChange',
        resolver: yupResolver<CreatePackageConfigModeled>(schema),
        defaultValues: {
            name:'',
            description:'',
            numberOfBooks: 1,
            price: 1,
            discountPrice: 0,
            active: false,
            type: '',
            photos: [],
            ageGroupId: '',
            weight: 0,
            slug:''
        },
    })

    const handleDelete = () => {
        // prettier-ignore
        handleClose()
    }

    const handleClose = () => {
        setShowCreateModal(false)
        setEditMode(false)
        reset()
    }

    const submit = (values: CreatePackageConfigModeled) => {
        // prettier-ignore
        (async () => {
            try {
                const selectedValuesMap = selectedEntries.reduce((acc, entry) => {
                    acc[entry] = (acc[entry] || 0) + 1;
                    return acc;
                }, {} as Record<string, number>);
                const photosPayload = uploadedImages.filter(value => value.key !== '').map(value => {return {key: value.key, url: value.url, main: value.main, index: value.index}})

                const data: ICreatePackageConfig = {
                    name: values.name,
                    description: values.description,
                    numberOfBooks: values.numberOfBooks,
                    ageGroup: { id : values.ageGroupId as UUID,
                                name :'', startAge: 0, endAge: 0},
                    price: values.price,
                    discountPrice: values.discountPrice,
                    active: values.active,
                    type: values.type,
                    selectedEntries: selectedValuesMap,
                    photos: photosPayload,
                    weight: values.weight,
                    slug:values.slug
                }

                if (editMode) {
                    const {status} = await updateCall(props?.selectedPackage?.id, data)
                    const response = await getAllCall({
                        page: page - 1,
                        size,
                    })
                    setTotalElements(response.data.totalElements)
                    setAllList(response.data.content)
                    if (status === 200 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Create Successful')
                    }
                } else {
                    const {status} = await createCall(data)
                    const response = await getAllCall({
                        page: page - 1,
                        size,
                    })
                    setTotalElements(response.data.totalElements)
                    setAllList(response.data.content)
                    if (status === 200 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Create Successful')
                    }
                }
            } catch (e) {
                if (e instanceof Error) {
                    setIsError(true)
                    setShowMessageBox(true)
                    setMessage(e.message)
                } else {
                    console.error(e)
                }
            }
        })()
        handleClose()
    }

    function getEntries() {
        return isDynamic ? allCategories : isStatic ?  allBooks : [];
    }

    return (
        <>
            <Modal
                show={showCreateModal}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="template-plan-form" onSubmit={handleSubmit(submit)}>
                        <Form.Group className="d-flex flex-column align-items-start gap-3">
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Name
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.name?.message}
                                    {...register('name')}
                                    type="text"
                                    placeholder="Enter Name"
                                />

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Number of books
                                </Form.Label>
                                <Form.Control
                                    disabled={editMode}
                                    isInvalid={!!errors?.numberOfBooks?.message}
                                    {...register('numberOfBooks')}
                                    type="number"
                                    placeholder="Enter number of books"
                                />
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Slug
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.slug?.message}
                                    {...register('slug')}
                                    type="text"
                                    placeholder="Enter Slug"
                                />
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!!errors?.description?.message}
                                        {...register('description')}
                                        type="text"
                                        placeholder="Enter description"
                                    />
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Age Group
                                    </Form.Label>
                                    <Form.Select className="d-flex flex-column mb-0"
                                                 disabled={editMode}
                                                 defaultValue={''}
                                                 isInvalid={!!errors?.ageGroupId?.message}
                                                 onChange={(e) => {
                                                     const val = e.target.value
                                                     setInnerAgeGroup(val);
                                                     setValue('ageGroupId', val, {shouldDirty: true})
                                                 }}
                                    >
                                        <option disabled value={''}>
                                            Select Internal Age Group
                                        </option>
                                        {map(allAgeGroups, (ageGroup) => (
                                            <option id={ageGroup.id} key={ageGroup.id} value={ageGroup.id} selected={ageGroup.id === getValues("ageGroupId")}>
                                                {ageGroup.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Form.Group>
                            <Form.Group content="groups" className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Price
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!!errors?.price?.message}
                                        {...register('price')}
                                        type="number"
                                        step={.01}
                                        defaultValue={0.00}
                                        placeholder="Enter Price"
                                    />

                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Discount Price
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!!errors?.discountPrice?.message}
                                        {...register('discountPrice')}
                                        type="number"
                                        step={.01}
                                        defaultValue={0.00}
                                        placeholder="Enter Discount Price"
                                    />

                                </div>
                            </Form.Group>

                            <Form.Group content="groups" className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Active
                                    </Form.Label>
                                    <Form.Check
                                        {...register('active')}
                                        type="checkbox"
                                    />

                                    <Form.Select className="d-flex flex-column mb-0"
                                                 disabled={editMode}
                                                 defaultValue={''}
                                                 onChange={(e) => {
                                                     const val = e.target.value
                                                     setInnerType(val)
                                                     setValue('type', val, {shouldDirty: true})
                                                 }}
                                    >
                                        <option disabled value={''}>
                                            Select Type
                                        </option>
                                        <option value={'DYNAMIC'} selected={'DYNAMIC'=== getValues("type")}>
                                            Dynamic - Category
                                        </option>
                                        <option value={'STATIC'} selected={'STATIC'=== getValues("type")}>
                                            Static - Books
                                        </option>
                                    </Form.Select>

                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Weight
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!!errors?.weight?.message}
                                        {...register('weight')}
                                        type="number"
                                        step={1}
                                        defaultValue={1}
                                        placeholder="Enter Weight"
                                    />
                                </div>
                            </Form.Group>

                                    <BookPhotosUploader
                                        uploadedImages={uploadedImages}
                                        setUploadedImages={setUploadedImages}
                                        errors = {errors}
                                        setValue = {setValue}
                                        isUploading = {isUploading}
                                        setIsUploading = {setIsUploading}
                                        setIsError = {setIsError}
                                        setShowMessageBox = {setShowMessageBox}
                                        setMessage = {setMessage}
                                        getEmptyImage={getEmptyImage}
                                    />
                            <Form.Group content="groups">

                                    {Array.from({ length: getValues('numberOfBooks') }).map((_, index) => (
                                        <Form.Group>
                                            <Form.Select
                                                disabled={editMode}
                                                key={index}
                                                className="d-flex flex-column mb-0"
                                                defaultValue={''}
                                                onChange={(e) => {
                                                    const val = e.target.value;
                                                    const updatedEntries = [...selectedEntries];
                                                    updatedEntries[index] = val;
                                                    setSelectedEntries(updatedEntries);
                                                }}
                                            >
                                                <option disabled value={''}>
                                                    Select Entry {index + 1}
                                                </option>
                                                {map(getEntries(), (entry) => (
                                                    <option id={entry.id} key={entry.id} value={entry.id} selected={entry.id === selectedEntries[index]}>
                                                        {entry.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <br/>
                                        </Form.Group>
                                    ))}
                            </Form.Group>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleClose}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary"
                        form="template-plan-form"
                        type="submit"
                        disabled={Object.keys(dirtyFields).length === 0}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <MessageBox
                message={message}
                setShowMessageBox={setShowMessageBox}
                showMessageBox={showMessageBox}
                isError={isError}
            />
        </>
    )
}
