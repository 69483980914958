import {BookFilterSearchPaginationParams, Pagination, PaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {
    IBook,
    IBookInstance, ICreatePackageConfig,
    ICreateTemplatePlan,
    Image,
    IReadingPlanTemplate,
    PackageConfig
} from "../core/interfaces";
import {UUID} from "node:crypto";
import {BookPayload} from "../pages/books/BookModal";
import {BookInstanceModel} from "../pages/books/BookInstanceModal";

export const getAllPackageConfigs = (params?: BookFilterSearchPaginationParams) =>
    http.get<Pagination<PackageConfig>>('/api/dashboard/books/packages/configs', {
        params,
    })


export const createPackageConfig = (data: ICreatePackageConfig) =>
    http.post<PackageConfig>('/api/dashboard/books/packages/configs', data)

export const updatePackageConfig = (id: string, data: ICreatePackageConfig) =>
    http.put<PackageConfig>(`/api/dashboard/books/packages/configs/${id}`, data)
