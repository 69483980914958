import {Pagination, SimpleSearchPaginationParams} from "../core/services/utils";
import {http} from "../core/services/http";
import {
    IBookInPlanGroup, IReadingPlanActivate,
    IReadingPlanRequest,
    IReadingPlanSummary,
    IReadingPlanTemplate,
    IReadingPlanTemplateLabel, UserKidInGroup
} from "../core/interfaces";


export const getAllPlansForIGroup = (groupId: string, params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<IReadingPlanSummary>>(`/api/dashboard/institution-groups/${groupId}/reading-plans`, {
        params,
    })


export const getAllKidsForIGroup = (groupId: string, params?: SimpleSearchPaginationParams) =>
    http.get<Pagination<UserKidInGroup>>(`/api/dashboard/institutions/groups/${groupId}/people`, {
        params,
    })

export const getAllKids = () =>
    http.get<UserKidInGroup[]>(`/api/dashboard/users/kids`)

export const addKidInGroup = (groupId: string, id : string) =>
    http.post(`/api/dashboard/institutions/groups/${groupId}/people/${id}`)

export const removeKidFromGroup = (groupId: string, id : string) =>
    http.delete(`/api/dashboard/institutions/groups/${groupId}/people/${id}`)

export const getReadingPlan = ( planId: string) =>
    http.get<IReadingPlanTemplate>(`/api/dashboard/reading-plan/${planId}`)

export const generateSchedulePlan = ( planId: string) =>
    http.put<IReadingPlanTemplate>(`/api/dashboard/reading-plan/${planId}/generate-schedule`)

export const activateReadingPlan = ( planId: string) =>
    http.put<IReadingPlanActivate>(`/api/dashboard/reading-plan/${planId}/activate`)

export const assignUsersPlan = ( planId: string) =>
    http.put<IReadingPlanTemplate>(`/api/dashboard/reading-plan/${planId}/assign-users`)

export const updateReadingPlan = (groupId: string, planId: string) =>
    http.put<IReadingPlanSummary>(`/api/dashboard/institution-groups/${groupId}/update-plan/${planId}`)

export const createReadingPlan = (groupId: string, request?: IReadingPlanRequest) =>
    http.put<IReadingPlanSummary>(`/api/dashboard/institution-groups/${groupId}/create-reading-plan`, request)

export const deleteReadingPlan = (groupId: string, params?: SimpleSearchPaginationParams) =>
    http.delete(`/api/dashboard/institution-groups/${groupId}/reading-plans`, {
        params,
    })

export const getAvailableBooksInPlan = (id: string) =>
    http.get<IBookInPlanGroup[]>(`/api/dashboard/reading-plan/${id}/available-books`)