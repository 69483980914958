import {IBookInPlanGroup, IReadingPlanTemplateGroup} from "../../core/interfaces";
import {Button, Form} from "react-bootstrap";
import {addBookToGroup, deleteBookFromGroup, getAvailableBooksInTemplate} from "../../services/templatePlans";
import {useEffect, useState} from "react";
import {map} from "lodash-es";

export interface PlanGroupProps {
    group?: IReadingPlanTemplateGroup
    avBooks?: IBookInPlanGroup[]
    getData?: () => Promise<void>
    allPrices?: boolean
}
export const PlanGroupDetails = (
    props: PlanGroupProps
): JSX.Element => {
    const {
        group,
        avBooks,
        getData
    } = props
    const [refresh, setRefresh] = useState<boolean>(false)

    async function refreshAvBooks() {
        const [books] = await Promise.all(
            [getAvailableBooksInTemplate(group.readingPlanTemplateId)])
        avBooks.splice(0, avBooks.length);
        books.data.forEach(book => avBooks.push(book))
    }

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, bookId: string, groupId: string): void => {
        deleteBookFromGroup(bookId, groupId).then(value => {
            var book = group.books.find(value => value.id == bookId);
            var bookIndex = group.books.findIndex(value => value.id == bookId);
            group.books[bookIndex] = {
                id: null,
                name: null,
                photos: null,
                catalogPrice: null,
                boughtPrice: null,
                salePrice: null,
                hasQuestionsAndActivities: null,
                numberOfInstances: null
            }
            refreshAvBooks().then(() => setRefresh(!refresh));
        })
        e.preventDefault();

        // window.location.reload();
    }

    const handleAdd = (bookId: string, groupId: string): void => {
        addBookToGroup(bookId, groupId).then(() => {
                refreshAvBooks().then(() => setRefresh(!refresh));
        })
    }
    useEffect(() => {
        getData()
    }, [refresh, props.allPrices]);

    return (
        <>
            <div style={{
                display: "inline-flex",
                backgroundColor: group?.color?.code,
                borderRadius: "20px",
                margin: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                flexWrap: "wrap",
                flexDirection: "row"
            }}>
                {group?.books.map(book =>
                    book?.name !== null ?
                        <div style={{
                            justifyContent: "center",
                            flex: "1",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "250px"
                        }} className={"xHover"}>
                            <Button
                                onClick={(e) => handleDelete(e, book.id, group.id)}
                                className="btn btn-close hidden"
                                type="button"
                                style={{color: '#FFF', fontSize: "xxx-large", position: "absolute", zIndex: 999}}
                            >
                            </Button>

                            {props.allPrices ?
                                <>
                                    <div style={{minWidth: "20%"}}>
                                        <span>BOOK PRICE {book.catalogPrice} vs {book.boughtPrice} vs {book.salePrice}</span>
                                        {/*<span>{book.catalogPrice} lei</span>*/}
                                    </div>
                                    <div style={{minWidth: "20%"}}>
                                        {100 - book.salePrice * 100 / book.boughtPrice > 40 ?
                                            <span style={{color: 'green'}}>ADAOS {(book.salePrice * 100 / book.boughtPrice-100).toFixed(2)}</span> :
                                            <span style={{color: 'red'}}>ADAOS {(book.salePrice * 100 / book.boughtPrice-100).toFixed(2)}</span>}
                                    </div>
                                    <div style={{minWidth: "20%"}}>
                                        {book.numberOfInstances > 3 ?
                                            <span style={{color: 'green'}}>!NO OF INSTANCES {book.numberOfInstances}</span>
                                            : <span>NO OF INSTANCES {book.numberOfInstances}</span>}
                                    </div>
                                    <div style={{minWidth: "20%"}}>
                                        {book.hasQuestionsAndActivities ?
                                            <span style={{color: 'green'}}>HAS QUESTIONS AND ACTIVITIES</span> :
                                            <span style={{color: 'red'}}>DOES NOT HAVE QUESTIONS</span>}
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{minWidth: "20%"}}>
                                        <span>{book.catalogPrice} lei</span>
                                    </div>
                                </>
                            }

                            <div style={{minWidth: "20%"}}><span>{book.name}</span></div>
                                    <div style={{margin: "auto"}}><img src={book?.photos[0]?.url}
                                                                       style={{maxWidth: "250px", maxHeight: "250px"}}/>
                                    </div>



                        </div>
                        :
                        <div style={{
                            justifyContent: "center",
                            flex: "1",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "20%"
                        }}>
                            <div style={{}}><span>PlaceHolder</span></div>
                            <div style={{margin: "auto", minHeight: "250px"}}>
                                <Form.Select className="d-flex flex-column mb-0"
                                             defaultValue={'Select book'}
                                             onChange={(e) => {
                                                 const val = e.target.value
                                                 handleAdd(val, group.id)
                                             }}
                                >
                                    <option value={''} key={''}>
                                        Select Book
                                    </option>
                                    {map(avBooks, (book) => (
                                        <option id={book.id} key={book.id} value={book.id}>
                                            {book.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </div>
                )}
                {props.allPrices ?
                    <>
                        <div style={{minWidth: "20%"}}>
                            {/*<span>GROUP PRICE {group?.catalogPrice} lei</span>*/}
                            <span>GROUP PRICE {group?.catalogPrice} vs {group?.boughtPrice}</span>
                        </div>

                    </> :
                    <>
                        <span>GROUP PRICE {group?.catalogPrice} lei</span>
                    </>
                }

            </div>
        </>
    );
}