import {IPhoto, UploadedPhoto} from "../../core/interfaces";
import {Form} from "react-bootstrap";
import {FormUploadPreview} from "../../core/components/FormUploadPreview";
import {ChangeEvent} from "react";
import {uploadImage} from "../../services/upload-image";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {UseFormSetValue} from "react-hook-form";

export interface UploaderProps {
    uploadedImages: UploadedPhoto[]
    setUploadedImages: (images: UploadedPhoto[]) => void
    errors: FieldErrors<any>
    setValue: UseFormSetValue<any>
    isUploading: boolean
    setIsUploading: (isUploading: boolean) => void
    setIsError: (isError: boolean) => void
    setShowMessageBox: (show: boolean) => void
    setMessage: (message: string) => void
    getEmptyImage: () => UploadedPhoto
}



export const BookPhotosUploader = (
    props: UploaderProps
): JSX.Element => {
    const {
        uploadedImages,
        setUploadedImages,
        errors,
        setValue,
        isUploading,
        setIsUploading,
        setIsError,
        setShowMessageBox,
        setMessage,
        getEmptyImage
    } = props


    function addUploadedImage(photo: UploadedPhoto) {
        setUploadedImages([...uploadedImages, photo].sort((a, b) => (a.key === '' ? 1 : b.key === '' ? -1 : 0)));
        setValue('photos', uploadedImages.map(value => value.url), {
            shouldDirty: true,
        })
    }

    function addEmptyPhoto() {
        return setUploadedImages([...uploadedImages, getEmptyImage()])
    }

    return (
        <>

            {uploadedImages.map((image, index) => {
                return (
                    <>
                        <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">

                            <FormUploadPreview
                                disabled={false}
                                control={
                                    <Form.Control
                                        multiple={false}
                                        accept="image/*"
                                        isInvalid={!!errors?.photos?.message}
                                        disabled={false}
                                        name="photo"
                                        onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                                            const file = e?.target?.files?.[0]
                                            if (file) {
                                                const data = await uploadImage(
                                                    file,
                                                    'books',
                                                    setIsUploading,
                                                    setIsError,
                                                    setShowMessageBox,
                                                    setMessage,
                                                )
                                                if (data.photoUrl) {
                                                    addUploadedImage({name: file.name, key: data.photoKey, url: data.photoUrl, main:false, index: uploadedImages.length})
                                                }
                                            }
                                        }}
                                        type="file"
                                    />
                                }
                                imageName={image.name}
                                direction="row"
                                isUploading={isUploading}
                                url={image.url}
                                onRemove={() => {
                                    setUploadedImages(uploadedImages.filter(img => img.key !== image.key));
                                    setValue('photos', uploadedImages.map(value => value.url), {
                                        shouldDirty: true,
                                    })
                                }}
                                imageStyle={{
                                    objectFit: 'contain',
                                }}
                                label="Photo"
                                previewLabel="Photo"
                            />
                            <Form.Check
                                type="radio"
                                name="mainPhoto"
                                label="Main"
                                checked={image.main}
                                onChange={() => {
                                    setUploadedImages(uploadedImages.map((img, i) => ({
                                        ...img,
                                        main: i === index
                                    })));
                                    setValue('photos', uploadedImages.map(value => value.url), {
                                        shouldDirty: true,
                                    })
                                }}
                            />
                            <Form.Control
                                type="number"
                                value={image.index}
                                className={"w-25"}
                                onChange={(e) => {
                                    const newIndex = parseInt(e.target.value, 10);
                                    setUploadedImages(uploadedImages.map((img, i) =>
                                        i === index ? {...img, index: newIndex} : img
                                    ));
                                    setValue('photos', uploadedImages.map(value => value.url), {
                                        shouldDirty: true,
                                    });
                                }}
                            />
                        </Form.Group>

                    </>
                );
            })}

        </>
    );
}