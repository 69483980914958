import {yupResolver} from '@hookform/resolvers/yup'
import {ChangeEvent, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import * as yup from 'yup'

import {DeleteConfirmationModal} from '../../core/components/DeleteConfirmationModal'
import {MessageBox} from '../../core/components/MessageBox'
import {IAuthor, IBook, IBookInstance, Image, INameId, IPhoto, UploadedPhoto} from "../../core/interfaces";
import {UUID} from "node:crypto";
import {Pagination, SimpleSearchPaginationParams} from "../../core/services/utils";
import {AxiosResponse} from 'axios';
import {getAllAuthorsWithData} from "../../services/authors";
import {getAllAgeCategories} from "../../services/ageCategories";
import {getAllCollections} from "../../services/collections";
import {getAllCategories} from "../../services/categories";
import {getAllIllustrators} from "../../services/illustrators";
import {getAllLanguages} from "../../services/langauges";
import {getAllPublishers} from "../../services/publishers";
import {map} from "lodash-es";
import Select from "react-select";
import {getAllTranslators} from "../../services/translators";
import {FormUploadPreview} from "../../core/components/FormUploadPreview";
import {uploadImage} from "../../services/upload-image";
import {getAllInternalAgeGroups} from "../../services/internalAgeGroups";
import {getAllGenres} from "../../services/genres";
import {BookPhotosUploader} from "../component/BookPhotosUploader";

export interface BookModalProps {
    showCreateModal: boolean
    setShowCreateModal: (show: boolean) => void
    setEditMode: (editMode: boolean) => void
    editMode: boolean
    page: number
    size: number
    setTotalElements: (elements: number) => void
    setAllList: (categories: IBook[]) => void
    bookEntity?: IBook
    createCall: (data: BookPayload) => Promise<AxiosResponse<IBook>>
    deleteCall: (id: UUID) => Promise<AxiosResponse<any>>
    updateCall: (id: UUID, data: BookPayload) => Promise<AxiosResponse<IBook>>
    getAllCall: (params?: SimpleSearchPaginationParams) => Promise<AxiosResponse<Pagination<IBook>>>
}


export interface NameIdPayload {
    id?: string,
}

export interface BookModeledId {
    name?: string
    description?: string
    isbn?: string
    publicationYear?: number
    numberOfPages?: number
    language?: NameIdPayload
    publisher?: NameIdPayload
    bookCollection?: NameIdPayload
    authors?: NameIdPayload[]
    illustrators?: NameIdPayload[]
    translators?: NameIdPayload[]
    categories?: NameIdPayload[]
    genres?: NameIdPayload[]
    ageCategories?: NameIdPayload[]
    ageGroups?: NameIdPayload[]
    width?: number
    height?: number
    catalogPrice?: number
    salePrice?: number
    photos?: string[]
    publisherLink?: string
    weight?: number
    timestamp?: number
    hiddenBook?: boolean
}



export interface BookPayload {
    id?: UUID
    name: string
    description?: string
    publicationYear?: number
    numberOfPages?: number
    language?: NameIdPayload
    publisher?: NameIdPayload
    isbn?: string
    bookCollection?: NameIdPayload
    stock?: number
    rented?: number
    reserved?: number
    illustrators?: NameIdPayload[]
    translators?: NameIdPayload[]
    authors?: NameIdPayload[]
    categories?: NameIdPayload[]
    genres?: NameIdPayload[]
    ageCategories?: NameIdPayload[]
    ageGroups?: NameIdPayload[]
    photos?: IPhoto[]
    instances?: IBookInstance[]
    width?: number
    height?: number
    catalogPrice?: number
    salePrice?: number
    publisherLink?: string
    weight?: number
    hiddenBook?: boolean
}

export const BookModal = (
    props: BookModalProps,
): JSX.Element => {
    const {
        bookEntity,
        setShowCreateModal,
        setAllList,
        showCreateModal,
        setEditMode,
        editMode,
        page,
        size,
        setTotalElements,
        createCall,
        deleteCall,
        updateCall,
        getAllCall

    } = props

    const [showDeleteConfirmationModal, setDeleteConfirmationModal] =
        useState<boolean>(false)

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [allAuthors, setAllAuthors] = useState<IAuthor[]>([])
    const [allCategories, setAllCategories] = useState<INameId[]>([])
    const [allGenres, setAllGenres] = useState<INameId[]>([])
    const [allAgeCategories, setAllAgeCategories] = useState<INameId[]>([])
    const [allAgeGroups, setAllAgeGroups] = useState<INameId[]>([])
    const [allCollections, setAllCollections] = useState<INameId[]>([])
    const [allIllustrators, setAllIllustrators] = useState<INameId[]>([])
    const [allTranslators, setAllTranslators] = useState<INameId[]>([])
    const [allLanguages, setAllLanguages] = useState<INameId[]>([])
    const [allPublishers, setAllPublishers] = useState<INameId[]>([])
    const [selectedAuthors, setSelectedAuthors] = useState([]);
    const [selectedIllustrator, setSelectedIllustrator] = useState([]);
    const [selectedTranslator, setSelectedTranslator] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedAgeCategories, setAgeSelectedCategories] = useState([]);
    const [selectedAgeGroups, setSelectedAgeGroups] = useState([]);
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [uploadedImages, setUploadedImages] = useState<UploadedPhoto[]>([getEmptyImage()])
    function getEmptyImage() {
        return {name: "", url: "", key: "", main: false, index: 0};
    }

    useEffect(() => {
        var getAllParams: SimpleSearchPaginationParams = {
            page: 0,
            size: 10000
        };
        (async () => {
            const [allAuthors, allCategories, allAgeCategories, allCollections, allIllustrators, allLanguages, allPublishers, allTranslators, allAgeGroups, allGenres] = await Promise.all(
                [getAllAuthorsWithData(getAllParams), getAllCategories(getAllParams), getAllAgeCategories(getAllParams), getAllCollections(getAllParams), getAllIllustrators(getAllParams), getAllLanguages(getAllParams), getAllPublishers(getAllParams), getAllTranslators(getAllParams), getAllInternalAgeGroups(getAllParams), getAllGenres(getAllParams)])
            setAllAuthors(allAuthors.data.content)
            setAllCategories(allCategories.data.content)
            setAllGenres(allGenres.data.content)
            setAllAgeCategories(allAgeCategories.data.content)
            setAllCollections(allCollections.data.content)
            setAllIllustrators(allIllustrators.data.content)
            setAllTranslators(allTranslators.data.content)
            setAllLanguages(allLanguages.data.content)
            setAllPublishers(allPublishers.data.content)
            setAllAgeGroups(allAgeGroups.data.content)
        })()
    }, [])


    useEffect(() => {
        if (editMode) {
            setValue('language', {id: bookEntity?.language.id})
            setValue('description', bookEntity?.description)
            setValue('publisher', {id: bookEntity?.publisher.id})
            setValue('bookCollection', {id: bookEntity?.bookCollection?.id})
            setValue('name', bookEntity?.name)
            setValue('isbn', bookEntity?.isbn)
            setValue('publicationYear', bookEntity?.publicationYear)
            setValue("authors", bookEntity?.authors.map((x) => {
                return {id: x.id, name: x.name};
            }))
            setValue("illustrators", bookEntity?.illustrators.map((x) => {
                return {id: x.id, name: x.name};
            }))
            setValue("translators", bookEntity?.translators.map((x) => {
                return {id: x.id, name: x.name};
            }))
            setValue("categories", bookEntity?.categories.map((x) => {
                return {id: x.id, name: x.name};
            }))
            setValue("genres", bookEntity?.genres.map((x) => {
                return {id: x.id, name: x.name};
            }))
            setValue("ageGroups", bookEntity?.ageGroups.map((x) => {
                return {id: x.id, name: x.name};
            }))
            setValue("ageCategories", bookEntity?.ageCategories.map((x) => {
                return {id: x.id, name: x.name};
            }))
            setSelectedAuthors(bookEntity?.authors.map(author => {
                return {value: author.id, label: author.name}
            }))
            setSelectedIllustrator(bookEntity?.illustrators.map(illustrator => {
                return {value: illustrator.id, label: illustrator.name}
            }))
            setSelectedTranslator(bookEntity?.translators.map(translator => {
                return {value: translator.id, label: translator.name}
            }))
            setSelectedCategories(bookEntity?.categories.map(cat => {
                return {value: cat.id, label: cat.name}
            }))
            setSelectedGenres(bookEntity?.genres.map(cat => {
                return {value: cat.id, label: cat.name}
            }))
            setAgeSelectedCategories(bookEntity?.ageCategories.map(ageCat => {
                return {value: ageCat.id, label: ageCat.name}
            }))
            setSelectedAgeGroups(bookEntity?.ageGroups.map(ageCat => {
                return {value: ageCat.id, label: ageCat.name}
            }))
            setValue("width", bookEntity?.width)
            setValue("height", bookEntity?.height)
            setValue("catalogPrice", bookEntity?.catalogPrice)
            setValue("salePrice", bookEntity?.salePrice)
            setValue("numberOfPages", bookEntity?.numberOfPages)
            setValue("photos", bookEntity?.photos.map(value => {return value.url}));
            setValue("publisherLink", bookEntity?.publisherLink)
            setValue("weight", bookEntity?.weight)
            setValue("hiddenBook", bookEntity?.hiddenBook)
            const photos = bookEntity?.photos.map(value => {
                return {key: value.key, url: value.url, name: value.key, main: value.main, index: value.index}
            });
            if (photos.filter(value => value.key==='').length === 0) {
                photos.push(getEmptyImage());
            }
            photos.sort((a, b) => (b.main ? 1 : a.main ? -1 : a.key === '' ? 1 : b.key === '' ? -1 : 0));
            setUploadedImages(photos)
        } else {
            setSelectedAuthors([]);
            setSelectedCategories([]);
            setSelectedGenres([]);
            setSelectedTranslator([]);
            setSelectedIllustrator([]);
            setAgeSelectedCategories([]);
            setSelectedAgeGroups([]);
            const photos = [];
            photos.push(getEmptyImage());
            setUploadedImages(photos)
        }
    }, [bookEntity, editMode])

    const schema = yup.object().shape({
        name: yup.string(),
        description: yup.string(),
        isbn: yup.string(),
        publicationYear: yup.number(),
        numberOfPages: yup.number(),
        language: yup.object()
            .shape({
                id: yup.string().uuid().required()
            }),
        publisher: yup.object()
            .shape({
                id: yup.string().uuid().required()
            }),
        bookCollection: yup.object()
            .shape({
                id: yup.string().nullable().optional()
            }),
        authors: yup
            .array().of(
                yup.object()
                    .shape({
                        id: yup.string().uuid().required()
                    })
            ),
        illustrators: yup
            .array().of(
                yup.object()
                    .shape({
                        id: yup.string().uuid().required()
                    })
            ),
        translators: yup
            .array().of(
                yup.object()
                    .shape({
                        id: yup.string().uuid().required()
                    })
            ),
        categories: yup
            .array().of(
                yup.object()
                    .shape({
                        id: yup.string().uuid().required()
                    })
            ),
        genres: yup
            .array().of(
                yup.object()
                    .shape({
                        id: yup.string().uuid().required()
                    })
            ),
        ageCategories: yup
            .array().of(
                yup.object()
                    .shape({
                        id: yup.string().uuid().required()
                    })
            ),
        ageGroups: yup
            .array().of(
                yup.object()
                    .shape({
                        id: yup.string().uuid().required()
                    })
            ).optional().nullable(),
        width: yup.number(),
        height: yup.number(),
        catalogPrice: yup.number(),
        salePrice: yup.number(),
        photos: yup.array(),
        publisherLink: yup.string().optional().nullable(),
        weight: yup.number(),
        timestamp: yup.number(),
        hiddenBook: yup.boolean().default(false)

    })

    const {
        handleSubmit,
        register,
        formState: {errors, dirtyFields},
        reset,
        setValue,
    } = useForm<BookModeledId>({
        mode: 'onChange',
        resolver: yupResolver<BookModeledId>(schema),
        defaultValues: {
            name: '',
            description: '',
            isbn: '',
            language: {},
            publisher: {},
            bookCollection: {},
            publicationYear: 2024,
            authors: [],
            categories: [],
            genres: [],
            ageCategories: [],
            ageGroups: [],
            illustrators: [],
            translators: [],
            width: 0,
            height: 0,
            catalogPrice: 0,
            salePrice: 0,
            numberOfPages: 0,
            photos: [],
            publisherLink: '',
            weight:1,
            timestamp:1,
            hiddenBook: false
        },
    })

    const handleDelete = () => {
        // prettier-ignore
        (async () => {
            try {
                if (bookEntity) {
                    const {status} = await deleteCall(bookEntity?.id)
                    const response = await getAllCall({
                        page: page - 1,
                        size,
                    })
                    setTotalElements(response.data.totalElements)
                    setAllList(response.data.content)
                    if (status === 204 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Delete Successful')
                    }
                }
            } catch (e) {
                if (e instanceof Error) {
                    setIsError(true)
                    setShowMessageBox(true)
                    setMessage(e.message)
                } else {
                    console.error(e)
                }
            }
        })()
        handleClose()
    }

    const handleClose = () => {
        setShowCreateModal(false)
        setEditMode(false)
        setUploadedImages([])
        setValue('photos', [])
        reset()
        setSelectedAuthors([]);
    }

    const submit = (values: BookModeledId) => {
        // prettier-ignore
        (async () => {
            try {


                const photosPayload = uploadedImages.filter(value => value.key !== '').map(value => {return {key: value.key, url: value.url, main: value.main, index: value.index}})
                console.log(values)
                const data: BookPayload = {
                    name: values.name,
                    isbn: values.isbn,
                    description: values.description,
                    publicationYear: values.publicationYear,
                    language: values.language,
                    publisher: values.publisher,
                    bookCollection: values.bookCollection.id != undefined && values.bookCollection.id != '' ? values.bookCollection : null,
                    authors: values.authors.map((x) => {
                        return {id: x.id};
                    }),
                    illustrators: values.illustrators.map((x) => {
                        return {id: x.id};
                    }),
                    translators: values.translators.map((x) => {
                        return {id: x.id};
                    }),
                    categories: values.categories.map((x) => {
                        return {id: x.id};
                    }),
                    genres: values.genres.map((x) => {
                        return {id: x.id};
                    }),
                    ageCategories: values.ageCategories.map((x) => {
                        return {id: x.id};
                    }),
                    ageGroups: values.ageGroups.map((x) => {
                        return {id: x.id};
                    }),
                    width: values.width,
                    height: values.height,
                    numberOfPages: values.numberOfPages,
                    catalogPrice: values.catalogPrice,
                    salePrice: values.salePrice,
                    hiddenBook: values.hiddenBook,
                    weight: values.weight,
                    photos: photosPayload,
                    publisherLink: values.publisherLink,
                }
                if (bookEntity) {
                    const {status} = await updateCall(bookEntity.id, data)
                    const response = await getAllCall({
                        page: page - 1,
                        size,
                    })
                    setAllList(response.data.content)
                    if (status === 200 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Update Successful')
                    }
                } else {
                    const {status} = await createCall(data)
                    const response = await getAllCall({
                        page: page - 1,
                        size,
                    })
                    setTotalElements(response.data.totalElements)
                    setAllList(response.data.content)
                    if (status === 200 && response.status === 200) {
                        setIsError(false)
                        setShowMessageBox(true)
                        setMessage('Create Successful')
                    }
                }
            } catch (e) {
                if (e instanceof Error) {
                    setIsError(true)
                    setShowMessageBox(true)
                    setMessage(e.message)
                } else {
                    console.error(e)
                }
            }
        })()
        handleClose()
    }


    return (
        <>
            <Modal
                show={showCreateModal}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop={"static"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editMode
                            ? `Edit ${bookEntity?.name}`
                            : `Add New`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="book-modal-form" onSubmit={handleSubmit(submit)}>
                        <Form.Group className="d-flex flex-column align-items-start gap-3">
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Name
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.name?.message}
                                    {...register('name')}
                                    type="text"
                                    placeholder="Enter Name"
                                />
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Description
                                </Form.Label>
                                <Form.Control as="textarea"
                                              isInvalid={!!errors?.description?.message}
                                              {...register('description')}
                                              type="area"
                                              placeholder="Enter Description"
                                />
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    ISBN
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.name?.message}
                                    {...register('isbn')}
                                    type="text"
                                    placeholder="Enter ISBN"
                                />
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Language
                                    </Form.Label>
                                    <Form.Select className="d-flex flex-column mb-0"
                                                 defaultValue={editMode ? bookEntity.language.id : ''}
                                                 isInvalid={!!errors?.language?.message}
                                                 onChange={(e) => {
                                                     const val = e.target.value
                                                     setValue('language', {id: val}, {shouldDirty: true})
                                                 }}
                                    >
                                        <option disabled value={''}>
                                            Select Language
                                        </option>
                                        {map(allLanguages, (language) => (
                                            <option id={language.id} key={language.id} value={language.id}>
                                                {language.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Publisher
                                    </Form.Label>
                                    <Form.Select className="d-flex flex-column mb-0"
                                                 defaultValue={editMode ? bookEntity.publisher.id : ''}
                                                 isInvalid={!!errors?.publisher?.message}
                                                 onChange={(e) => {
                                                     const val = e.target.value
                                                     setValue('publisher', {id: val}, {shouldDirty: true})
                                                 }}
                                    >
                                        <option disabled value={''}>
                                            Select Publisher
                                        </option>
                                        {map(allPublishers, (pub) => (
                                            <option id={pub.id} key={pub.id} value={pub.id}>
                                                {pub.name}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Book Collection
                                    </Form.Label>
                                    <Form.Select className="d-flex flex-column mb-0"
                                                 defaultValue={editMode ? bookEntity.bookCollection?.id : ''}
                                                 onChange={(e) => {
                                                     const val = e.target.value
                                                     setValue('bookCollection', {id: val}, {shouldDirty: true})
                                                 }}
                                    >
                                        <option value={''} key={''}>
                                            Select Book Collection
                                        </option>
                                        {map(allCollections, (colls) => (
                                            <option id={colls.id} key={colls.id} value={colls.id}>
                                                {colls.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Publication Year
                                </Form.Label>
                                <Form.Control
                                              isInvalid={!!errors?.publicationYear?.message}
                                              {...register('publicationYear')}
                                              type="number"
                                              placeholder="Enter Publication year"
                                />
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Publisher Link
                                    </Form.Label>
                                    <Form.Control
                                        isInvalid={!!errors?.name?.message}
                                        {...register('publisherLink')}
                                        type="text"
                                        placeholder="Enter Publisher link"
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Authors
                                    </Form.Label>
                                    <Select
                                        isMulti={true}
                                        defaultValue={undefined}
                                        options={allAuthors.map(author => {
                                            return {value: author.id, label: author.name}
                                        })}
                                        value={selectedAuthors}
                                        onChange={(e) => {
                                            const selectedAuthors = Array.from(e, option => {
                                                return {value: option.value, label: option.label};
                                            });
                                            setSelectedAuthors(selectedAuthors);
                                            let authorsMap = selectedAuthors.map((x) => {
                                                return {id: x.value};
                                            });
                                            setValue('authors', authorsMap, {shouldDirty: true})
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Illustrators
                                    </Form.Label>
                                    <Select
                                        isMulti={true}
                                        defaultValue={undefined}
                                        options={allIllustrators.map(illustrator => {
                                            return {value: illustrator.id, label: illustrator.name}
                                        })}
                                        value={selectedIllustrator}
                                        onChange={(e) => {
                                            const selectedIllustrator = Array.from(e, option => {
                                                return {value: option.value, label: option.label};
                                            });
                                            setSelectedIllustrator(selectedIllustrator);
                                            let illMap = selectedIllustrator.map((x) => {
                                                return {id: x.value};
                                            });
                                            setValue('illustrators', illMap, {shouldDirty: true})
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Translators
                                    </Form.Label>
                                    <Select
                                        isMulti={true}
                                        defaultValue={undefined}
                                        options={allTranslators.map(translator => {
                                            return {value: translator.id, label: translator.name}
                                        })}
                                        value={selectedTranslator}
                                        onChange={(e) => {
                                            const selectedTranslators = Array.from(e, option => {
                                                return {value: option.value, label: option.label};
                                            });
                                            setSelectedTranslator(selectedTranslators);
                                            let illMap = selectedTranslators.map((x) => {
                                                return {id: x.value};
                                            });
                                            setValue('translators', illMap, {shouldDirty: true})
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-3">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Genre
                                    </Form.Label>
                                    <Select
                                        isMulti={true}
                                        defaultValue={undefined}
                                        options={allGenres.map(genre => {
                                            return {value: genre.id, label: genre.name}
                                        })}
                                        value={selectedGenres}
                                        onChange={(e) => {
                                            const selectedGenres = Array.from(e, option => {
                                                return {value: option.value, label: option.label};
                                            });
                                            setSelectedGenres(selectedGenres);
                                            let cateMap = selectedGenres.map((x) => {
                                                return {id: x.value};
                                            });
                                            setValue('genres', cateMap, {shouldDirty: true})
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">
                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Categories
                                    </Form.Label>
                                    <Select
                                        isMulti={true}
                                        defaultValue={undefined}
                                        options={allCategories.map(category => {
                                            return {value: category.id, label: category.name}
                                        })}
                                        value={selectedCategories}
                                        onChange={(e) => {
                                            const selectedCategories = Array.from(e, option => {
                                                return {value: option.value, label: option.label};
                                            });
                                            setSelectedCategories(selectedCategories);
                                            let cateMap = selectedCategories.map((x) => {
                                                return {id: x.value};
                                            });
                                            setValue('categories', cateMap, {shouldDirty: true})
                                        }}
                                    />

                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Age Categories
                                    </Form.Label>
                                    <Select
                                        isMulti={true}
                                        defaultValue={undefined}
                                        options={allAgeCategories.map(category => {
                                            return {value: category.id, label: category.name}
                                        })}
                                        value={selectedAgeCategories}
                                        onChange={(e) => {
                                            const selectedAgeCategories = Array.from(e, option => {
                                                return {value: option.value, label: option.label};
                                            });
                                            setAgeSelectedCategories(selectedAgeCategories);
                                            let cateMap = selectedAgeCategories.map((x) => {
                                                return {id: x.value};
                                            });
                                            setValue('ageCategories', cateMap, {shouldDirty: true})
                                        }}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group content="Book Details"
                                        className="d-flex align-items-center gap-3 align-self-stretch">
                                <div className="d-flex align-items-start gap-2 w-100">

                                    <Form.Label
                                        style={{
                                            fontSize: '16px',
                                            fontFamily: 'Helvetica Neue',
                                            fontWeight: '700',
                                            lineHeight: '150%',
                                            fontStyle: 'normal',
                                            width: '132px',
                                        }}
                                        className="d-flex flex-column mb-0"
                                    >
                                        Internal Age Categories
                                    </Form.Label>
                                    <Select
                                        isMulti={true}
                                        defaultValue={undefined}
                                        options={allAgeGroups.map(category => {
                                            return {value: category.id, label: category.name}
                                        })}
                                        value={selectedAgeGroups}
                                        onChange={(e) => {
                                            const selectedAgeGroups = Array.from(e, option => {
                                                return {value: option.value, label: option.label};
                                            });
                                            setSelectedAgeGroups(selectedAgeGroups);
                                            let cateMap = selectedAgeGroups.map((x) => {
                                                return {id: x.value};
                                            });

                                            console.log(selectedAgeGroups);
                                            console.log(cateMap);
                                            setValue('ageGroups', cateMap, {shouldDirty: true, shouldTouch: true})
                                        }}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Number of Pages
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.width?.message}
                                    {...register('numberOfPages')}
                                    type="number"
                                    step={1}
                                    placeholder="Enter Number Of Pages"
                                />

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Catalog Price
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.catalogPrice?.message}
                                    {...register('catalogPrice')}
                                    type="number"
                                    step={.01}
                                    defaultValue={0.00}
                                    placeholder="Enter Catalog Price"
                                />

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Sale Price
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.salePrice?.message}
                                    {...register('salePrice')}
                                    type="number"
                                    step={.01}
                                    defaultValue={0.00}
                                    placeholder="Enter Sale Price"
                                />

                            </Form.Group>
                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Width
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.width?.message}
                                    {...register('width')}
                                    type="number"
                                    step={1}
                                    placeholder="Enter Width"
                                />

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Height
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.height?.message}
                                    {...register('height')}
                                    type="number"
                                    step={1}
                                    placeholder="Enter Height"
                                />
                            </Form.Group>

                            <Form.Group className="d-flex align-items-center gap-3 align-self-stretch">
                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Weight
                                </Form.Label>
                                <Form.Control
                                    isInvalid={!!errors?.weight?.message}
                                    {...register('weight')}
                                    type="number"
                                    step={1}
                                    placeholder="Enter weight"
                                />

                                <Form.Label
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: '700',
                                        lineHeight: '150%',
                                        fontStyle: 'normal',
                                        width: '132px',
                                    }}
                                    className="d-flex flex-column mb-0"
                                >
                                    Hide book?
                                </Form.Label>
                                <input
                                    {...register('hiddenBook')}
                                    type="checkbox"
                                    placeholder="Hide book?"
                                    onChange={(e) => {
                                        setValue('hiddenBook', e.target.checked, { shouldDirty: true })
                                        setValue('timestamp', Date.now(), { shouldDirty: true })

                                    }
                                }
                                />
                            </Form.Group>
                        </Form.Group>
                        <div style={{margin: 16}}>

                            <BookPhotosUploader
                                uploadedImages={uploadedImages}
                                setUploadedImages={setUploadedImages}
                                errors = {errors}
                                setValue = {setValue}
                                isUploading = {isUploading}
                                setIsUploading = {setIsUploading}
                                setIsError = {setIsError}
                                setShowMessageBox = {setShowMessageBox}
                                setMessage = {setMessage}
                                getEmptyImage={getEmptyImage}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editMode && (
                        <Button
                            onClick={handleDelete}
                            style={{color: '#FFFFFF'}}
                            className="btn btn-danger me-auto "
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        onClick={handleClose}
                        className="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary"
                        form="book-modal-form"
                        type="submit"
                        disabled={Object.keys(dirtyFields).length === 0}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteConfirmationModal
                showDeleteConfirmationModal={showDeleteConfirmationModal}
                setDeleteConfirmationModal={setDeleteConfirmationModal}
                deleteTitle={'Delete'}
                deleteFunc={handleDelete}
            />
            <MessageBox
                message={message}
                setShowMessageBox={setShowMessageBox}
                showMessageBox={showMessageBox}
                isError={isError}
            />
        </>
    )
}
